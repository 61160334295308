import { FC } from "react";
import styled from "styled-components";
import { Text } from "../components/Typography";
import Spinner from "../components/Spinner";
import MapLogo from "../components/MapLogo";

const LoadingPositionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 40px;
  flex: 1 1 100%;
  height: 100%;
`;

const LoadingPosition: FC = () => (
  <LoadingPositionContainer>
    <Spinner size={80} alt="Laden" />
    {/*TODO half the distance than to the logo*/}
    <Text>Standort wird gesucht...</Text>
    <MapLogo />
  </LoadingPositionContainer>
);

export default LoadingPosition;
