import { FC, useEffect } from "react";
import {
  MapContainer as LeafletMap,
  Marker,
  Popup,
  TileLayer,
} from "react-leaflet";
import * as Leaflet from "leaflet";
import MarkerIcon from "leaflet/dist/images/marker-icon.png";
import styled from "styled-components";
import useGeoTracker from "../hooks/geoTracker";
import LoadingPosition from "./LoadingPosition";
import { useAppSelector } from "../app/hooks";
import { Text, types, weights } from "../components/Typography";
import { useConfig } from "../context/Config";
import { Secondary, Option, Background } from "../utils/AppColor";
import logo from "../assets/logo.svg";
import "leaflet/dist/leaflet.css";

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 5%;
  justify-content: center;
  align-items: center;
  background-color: ${Option};
  padding: 10px;
`;

const A = styled.a`
  color: ${Background};
  text-decoration: underline;
  :visited {
    color: ${Background};
  }
`;

const KeepWindowOpenBanner: FC = () => (
  <Banner>
    <Text weight={weights.bold} type={types.background}>
      Bitte lassen Sie dieses Fenster geöffnet.
    </Text>
    <Text type={types.background}>
      <A
        href={
          "https://www.nora-notruf.de/de-as/sms-ortung-rechtliches/anbieter"
        }
        target={"_blank"}
      >
        Anbieter
      </A>
      {" / "}
      <A
        href={
          "https://www.nora-notruf.de/de-as/sms-ortung-rechtliches/sms-ortung-datenschutz"
        }
        target={"_blank"}
      >
        Datenschutz
      </A>
      {" / "}
      <A
        href={"https://www.nora-notruf.de/de-as/sms-ortung-rechtliches"}
        target={"_blank"}
      >
        Rechtliches
      </A>
    </Text>
  </Banner>
);

const LocationAccuracy = styled.div`
  display: flex;
  flex: 1 0 100%;
  justify-content: center;
  position: absolute;
  bottom: 8px;
  width: 100%;
  z-index: 999;
`;

const Accuracy = styled(Text)`
  padding: 10px;
  border-radius: 20px;
  background-color: ${Secondary};
  white-space: nowrap;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Positioner = styled.div`
  position: relative;
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
  text-align: center;
`;

const Map = styled(LeafletMap)`
  flex: 1 1 100%;
  height: 100%;
  width: 100%;
`;
const locationMarkerIcon = Leaflet.icon({
  iconUrl: MarkerIcon,
  iconSize: [25, 40],
  iconAnchor: [13, 40],
});

export const MapScreen: FC<{
  psapName: string;
  position: Leaflet.LatLngTuple;
  accuracy: number | null;
}> = ({ psapName, position, accuracy }) => {
  const { config } = useConfig();
  return (
    <>
      <Header>
        <img
          src={logo}
          style={{
            marginTop: 19,
            marginBottom: 16,
            maxWidth: 200,
          }}
        />
        <Text>Ihr Standort wird übertragen an:</Text>
        <Text>{psapName}</Text>
      </Header>
      <Positioner>
        {accuracy !== null && (
          <LocationAccuracy>
            <Accuracy type={types.background}>
              {`Standort-Genauigkeit: ${Math.round(accuracy)}m`}
            </Accuracy>
          </LocationAccuracy>
        )}
        <Map center={position} zoom={16} zoomControl={false} boxZoom={false}>
          <TileLayer
            maxZoom={19}
            url={config.osm.tileUrl}
            subdomains={config.osm.fqdns}
          />
          <Marker position={position} icon={locationMarkerIcon}>
            <Popup>Ihr Standort</Popup>
          </Marker>
        </Map>
      </Positioner>
      <KeepWindowOpenBanner />
    </>
  );
};

const MapContainer: FC<{ psapName: string }> = ({ psapName }) => {
  const { subscribe } = useGeoTracker();
  const tracking = useAppSelector((state) => state.tracking);

  useEffect(() => {
    subscribe();
  }, []);

  const position: Leaflet.LatLngTuple | undefined =
    tracking.latitude && tracking.longitude
      ? [tracking.latitude, tracking.longitude]
      : undefined;

  if (!position) {
    return (
      <>
        <LoadingPosition />
        <KeepWindowOpenBanner />
      </>
    );
  }

  const accuracy = tracking.accuracy;

  return (
    <MapScreen psapName={psapName} accuracy={accuracy} position={position} />
  );
};

export default MapContainer;
