import React, { FC } from "react";
import styled, { css } from "styled-components";
import {
  Active,
  Background,
  Primary,
  Emphasized,
  Important,
  Secondary,
  Option,
  Hint,
  Warn,
  Urgent,
} from "../utils/AppColor";

export enum types {
  active = 0,
  primary = 1,
  secondary = 2,
  option = 3,
  important = 4,
  background = 5,
  emphasized = 6,
  hint = 7,
  warn = 8,
  urgent = 9,
}

const typeToColor = {
  [types.active]: Active,
  [types.primary]: Primary,
  [types.secondary]: Secondary,
  [types.option]: Option,
  [types.important]: Important,
  [types.background]: Background,
  [types.emphasized]: Emphasized,
  [types.hint]: Hint,
  [types.warn]: Warn,
  [types.urgent]: Urgent,
};

export enum weights {
  regular = "regular",
  medium = "medium",
  bold = "bold",
}

const fontWeights = {
  [weights.regular]: 400,
  [weights.medium]: 500,
  [weights.bold]: 700,
};

export enum sizes {
  caption,
  small,
  regular,
  large,
  huge,
}

const fontSizes = {
  [sizes.caption]: 12,
  [sizes.regular]: 16,
  [sizes.large]: 18,
  [sizes.huge]: 24,
  [sizes.small]: 14,
};

interface HeadingStyle {
  margin?: number;
  type?: types;
  weight?: weights;
}

const sharedHeadingStyle = css<HeadingStyle>`
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: ${({ weight }) => fontWeights[weight || weights.bold]};
  color: ${({ type }) => typeToColor[type as types] || Active};
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

const H1 = styled.h1`
  font-size: ${fontSizes[sizes.huge]}px;
  line-height: 110%;
  ${sharedHeadingStyle}
`;

const H2 = styled.h2`
  font-size: ${fontSizes[sizes.large]}px;
  line-height: 160%;
  ${sharedHeadingStyle}
`;

const H3 = styled.h3`
  font-size: ${fontSizes[sizes.regular]}px;
  line-height: 110%;
  ${sharedHeadingStyle}
`;

const H4 = styled.h4`
  font-size: ${fontSizes[sizes.small]}px;
  line-height: 110%;
  ${sharedHeadingStyle}
`;

const H5 = styled.h5`
  font-size: ${fontSizes[sizes.caption]}px;
  line-height: 110%;
  ${sharedHeadingStyle}
`;

const levelToComponent = {
  1: H1,
  2: H2,
  3: H3,
  4: H4,
  5: H5,
};

export const Heading: FC<
  HeadingStyle & { children?: React.ReactNode; level?: 1 | 2 | 3 | 4 | 5 }
> = ({ children, level, margin, type, weight }) => {
  const Component = levelToComponent[level as number] || H2;
  return (
    <Component type={type} margin={margin} weight={weight}>
      {children}
    </Component>
  );
};

interface TextProps {
  size?: sizes;
  weight?: weights;
  italic?: boolean;
  color?: string;
  margin?: number | string;
  indent?: boolean;
  type?: types;
}

export const Text = styled.span<TextProps>`
  line-height: 120%;
  font-size: ${({ size }) =>
    fontSizes[size as sizes] || fontSizes[sizes.regular]}px;
  font-weight: ${({ weight }) => fontWeights[weight || weights.regular]};
  ${({ italic }) => italic && "font-style: italic;"}
  color: ${({ type }) => typeToColor[type as types] || Active};
  margin: ${({ margin }) => (margin ? margin : 0)};
  ${({ indent }) => indent && "text-indent: 20px;"}
`;

export const Header: FC<TextProps> = (props) => <Text {...props} as="header" />;

export const Label: FC<TextProps & { htmlFor: string }> = (props) => (
  <Text {...props} as="label" />
);

export const Paragraph: FC<TextProps> = (props) => <Text {...props} as="p" />;

export const Emphasis: FC<TextProps> = (props) => (
  <Text {...props} italic as="em" />
);
