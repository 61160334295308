import { FC, ReactNode } from "react";
import { Text } from "./Typography";
import { Active, Secondary } from "../utils/AppColor";
import { TrackingErrorType } from "../features/tracking";
import styled from "styled-components";
import logo from "../assets/logo.svg";

import noLocation from "../assets/NoLocation.svg";
import noConnection from "../assets/NoConnection.svg";

const Logo = styled.img`
  margin-top: 19px;
  margin-bottom: 16px;
  max-width: 160px;
`;

const ErrorImage = styled.img`
  max-width: 150px;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 47px;
`;

const ErrorText = styled(Text)`
  white-space: pre-line;
`;

const List = styled.ol`
  padding-left: 20px;
`;

const A = styled.a`
  text-decoration: underline;
  color: ${Active};
  :visited {
    color: ${Secondary};
  }
`;

const Error: FC<{ message: ReactNode; imageSrc: string }> = ({
  message,
  imageSrc,
}) => (
  <Container>
    <Logo src={logo} />
    <ErrorText>{message}</ErrorText>
    <ErrorImage src={imageSrc} />
    <p>
      <Text>
        <A
          href={
            "https://www.nora-notruf.de/de-as/sms-ortung-rechtliches/anbieter"
          }
          target={"_blank"}
        >
          Anbieter
        </A>
        {" / "}
        <A
          href={
            "https://www.nora-notruf.de/de-as/sms-ortung-rechtliches/sms-ortung-datenschutz"
          }
          target={"_blank"}
        >
          Datenschutz
        </A>
        {" / "}
        <A
          href={"https://www.nora-notruf.de/de-as/sms-ortung-rechtliches"}
          target={"_blank"}
        >
          Rechtliches
        </A>
      </Text>
    </p>
  </Container>
);

export const NoConnectionError: FC = () => (
  <Error
    message={
      "Wir können Sie nicht mit den Notruf-Servern verbinden.\n\nBitte überprüfen Sie Ihre Internetverbindung."
    }
    imageSrc={noConnection}
  />
);

export const TrackingClosedByPSAPError: FC = () => (
  <Error
    message={
      "Die Leitstelle hat die Standort-Anfrage beendet.\n\nIhr Standort wird nicht mehr geteilt."
    }
    imageSrc={noLocation}
  />
);

export const UnknownCallError: FC = () => (
  <Error
    message={
      "Die Anfrage ist nicht oder nicht mehr gültig.\n\nIhr Standort wird nicht geteilt."
    }
    imageSrc={noLocation}
  />
);

const TrackingTimeoutError: FC = () => (
  <Error
    message={
      "Die Standort-Ermittlung benötigt zu lange.\n\nIhr Standort wird nicht geteilt.\n\nMöchten Sie es noch einmal versuchen?\n\nDann laden Sie diese Seite über “Aktualisieren” neu."
    }
    imageSrc={noLocation}
  />
);

const TrackingPositionUnavailableError: FC = () => (
  <Error
    message={"Ihr Standort konnte nicht ermittelt werden."}
    imageSrc={noLocation}
  />
);

const TrackingUnsupportedDeviceError: FC = () => (
  <Error
    message={
      <>
        Mit diesem Browser ist die Standort-Ermittlung nicht möglich.
        <br />
        <br />
        Ihr Standort wird nicht geteilt.
        <br />
        <br />
        Wenn möglich, nutzen Sie einen anderen Browser:
        <List>
          <li>
            Drücken Sie lange auf die Adresse in der erhaltenen SMS, um sie zu
            kopieren.
          </li>
          <li>Öffnen Sie einen anderen Browser.</li>
          <li>
            Drücken Sie lange in das Adressfeld des Browsers, um die Adresse
            einzufügen.
          </li>
        </List>
      </>
    }
    imageSrc={noLocation}
  />
);

const TrackingPermissionDeniedError: FC = () => (
  <Error
    message={
      "Bitte erlauben Sie den Standortzugriff.\n\nOhne diese Berechtigung können Sie die Ortungs-Funktion nicht nutzen."
    }
    imageSrc={noLocation}
  />
);

const TrackingError: FC<{ error: TrackingErrorType }> = ({ error }) => {
  switch (error) {
    case TrackingErrorType.Timeout:
      return <TrackingTimeoutError />;
    case TrackingErrorType.UnsupportedDevice:
      return <TrackingUnsupportedDeviceError />;
    case TrackingErrorType.PermissionDenied:
      return <TrackingPermissionDeniedError />;
    case TrackingErrorType.PositionUnavailable:
    default:
      return <TrackingPositionUnavailableError />;
  }
};

export default TrackingError;
