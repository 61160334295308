import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Position {
  timestamp: number;
  coords: {
    accuracy: number;
    latitude: number;
    longitude: number;
  };
}

export enum TrackingErrorType {
  UnsupportedDevice,
  PermissionDenied,
  PositionUnavailable,
  Timeout,
}

export interface TrackingState {
  error: TrackingErrorType | null;
  timestamp: number | null;
  accuracy: number | null;
  latitude: number | null;
  longitude: number | null;
}

const initialState: TrackingState = {
  error: null,
  timestamp: null,
  accuracy: null,
  latitude: null,
  longitude: null,
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    updatePosition: (
      state: TrackingState,
      { payload }: PayloadAction<Position>
    ) => {
      console.log({ coords: payload.coords });
      return {
        ...state,
        timestamp: payload.timestamp,
        latitude: payload.coords.latitude,
        longitude: payload.coords.longitude,
        accuracy: payload.coords.accuracy,
      };
    },

    updateError: (
      state: TrackingState,
      { payload }: PayloadAction<TrackingErrorType>
    ) => ({
      ...state,
      error: payload,
    }),

    clearError: (state: TrackingState) => ({
      ...state,
      error: null,
    }),
  },
});

export const { updateError, clearError, updatePosition } =
  trackingSlice.actions;
export default trackingSlice.reducer;
