import { useAppDispatch } from "../app/hooks";
import {
  updateError,
  clearError,
  updatePosition,
  TrackingErrorType,
  Position,
} from "../features/tracking";
import { useClient } from "../context/Client";

type FunctionOrValue<T> = (() => T) | T;

const getError = (error: GeolocationPositionError): TrackingErrorType => {
  switch (error.code) {
    case GeolocationPositionError.POSITION_UNAVAILABLE: {
      return TrackingErrorType.PositionUnavailable;
    }
    case GeolocationPositionError.TIMEOUT: {
      return TrackingErrorType.Timeout;
    }
    default:
    case GeolocationPositionError.PERMISSION_DENIED: {
      return TrackingErrorType.PermissionDenied;
    }
  }
};

const options = {
  enableHighAccuracy: true,
  maximumAge: 0,
};

const useGeoTracker = (): { subscribe: () => FunctionOrValue<void> } => {
  const dispatch = useAppDispatch();
  const { updateLocation } = useClient();
  // Potential workarounds for issues on some Android devices:
  // https://tech.namshi.io/blog/2016/11/13/browser-geolocation-the-good-the-bad-and-the-ugly/
  const subscribe = () => {
    if (!navigator.geolocation) {
      dispatch(updateError(TrackingErrorType.UnsupportedDevice));
      return;
    }

    console.log("GeoTracker start, options:", options);

    let watchId: number | undefined = undefined;

    const stopWatch = () => {
      console.log("GeoTracker stop");
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };

    watchId = navigator.geolocation.watchPosition(
      ({ coords: c, timestamp }) => {
        const coords: Position["coords"] = {
          latitude: c.latitude,
          longitude: c.longitude,
          accuracy: c.accuracy,
        };
        console.log("GeoTracker position:", coords);
        updateLocation({ coords, timestamp: Math.floor(timestamp / 1000) }); // convert timestamp to seconds instead of miliseconds
        dispatch(updatePosition({ coords, timestamp }));
        dispatch(clearError());
      },
      (error) => {
        // NOTE: The watch keeps on watching even when
        // this callback was invoked. It may in fact be
        // called multiple times.

        // TODO: Perhaps show a corresponding message when
        // we couldn't obtain a position until a new one
        // comes in?
        console.log("GeoTracker error:", error);

        dispatch(updateError(getError(error)));

        if (error.code === GeolocationPositionError.PERMISSION_DENIED) {
          stopWatch();
        }
      },
      options
    );

    return stopWatch;
  };

  return { subscribe };
};

export default useGeoTracker;
