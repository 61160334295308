import { useState, FC } from "react";
import { useAppSelector } from "../app/hooks";
import LoadingApp from "../screens/LoadingApp";
import ConnectScreen from "../screens/Connect";
import { useClient, CallKinds } from "../context/Client";

import MapScreen from "../screens/Map";

import TrackingError, {
  NoConnectionError,
  TrackingClosedByPSAPError,
  UnknownCallError,
} from "../components/Errors";

// Route parameters
export interface MainViewRouteParams {
  id: string;
}

const ComponentForStep: FC<{ psapName: string }> = ({ psapName }) => {
  const [trackingStarted, setTrackingStarted] = useState(false);
  if (!trackingStarted) {
    return (
      <ConnectScreen
        psapName={psapName}
        onTrackingStart={() => setTrackingStarted(true)}
      />
    );
  }
  return <MapScreen psapName={psapName} />;
};

export const MainView: FC = () => {
  const { loading, connected, callStatus } = useClient();
  const tracking = useAppSelector((state) => state.tracking);

  if (loading || !callStatus || connected === undefined) {
    return <LoadingApp />;
  }

  if (!connected) {
    return <NoConnectionError />;
  }

  if (tracking.error !== null) {
    return <TrackingError error={tracking.error} />;
  }

  switch (callStatus.kind) {
    case CallKinds.Unknown:
      return <UnknownCallError />;
    case CallKinds.Closed:
      return <TrackingClosedByPSAPError />;
    case CallKinds.Active:
  }

  return <ComponentForStep psapName={callStatus.psapName} />;
};
