import { FC } from "react";
import styled from "styled-components";
import Button, { types } from "../components/Button";
import { Text, Heading } from "../components/Typography";

import logo from "../assets/logo.svg";
import check from "../assets/check.svg";
import { Active, Secondary } from "../utils/AppColor";

const StartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CenteredButton = styled(Button)`
  align-self: center;
  margin: 8px 0;
`;

const CenteredText = styled(Text)`
  align-self: center;
  margin-top: 20px;
`;

const Line = styled.hr`
  border: 0;
  border-top: 2px solid ${Secondary};
  margin: 20px 0;
`;

const A = styled.a`
  text-decoration: underline;
  color: ${Active};
  :visited {
    color: ${Secondary};
  }
`;

const Logo = styled.img`
  min-width: 160px;
  width: 45%;
  max-width: 280px;
`;

const Top = styled.div`
  flex: 1;
`;
const Bottom = styled.div`
  flex: 1;
  min-height: 16px;
`;

const ConnectScreen: FC<{ psapName: string; onTrackingStart: () => void }> = ({
  psapName,
  onTrackingStart,
}) => (
  <StartContainer>
    <Top />
    <Logo src={logo} />
    <Bottom />
    <ContentContainer>
      <Text>Teilen Sie ihren Live-Standort mit {psapName}.</Text>
      <Line />
      <Heading>Nutzungs-Bedingungen</Heading>
      <p>
        <Text>
          Ich stimme den{" "}
          <A
            href={
              "https://www.nora-notruf.de/de-as/sms-ortung-rechtliches/nutzungs-bedingungen"
            }
            target={"_blank"}
          >
            Nutzungs-Bedingungen
          </A>{" "}
          zu.
        </Text>
      </p>
      <Heading>Datenschutz</Heading>
      <p>
        <Text>
          Die{" "}
          <A
            href={
              "https://www.nora-notruf.de/de-as/sms-ortung-rechtliches/sms-ortung-datenschutz"
            }
            target={"_blank"}
          >
            Datenschutz-Erklärung
          </A>{" "}
          habe ich zur Kenntnis genommen.
        </Text>
      </p>
      <CenteredButton
        type={types.option}
        prefix={<img src={check} alt="Standort" />}
        onClick={onTrackingStart}
        large
      >
        Bestätigen und Standort senden
      </CenteredButton>
      <CenteredText>
        <A
          href={
            "https://www.nora-notruf.de/de-as/sms-ortung-rechtliches/anbieter"
          }
          target={"_blank"}
        >
          Anbieter
        </A>
        {" / "}
        <A
          href={
            "https://www.nora-notruf.de/de-as/sms-ortung-rechtliches/sms-ortung-lizenzen"
          }
          target={"_blank"}
        >
          Lizenzen
        </A>
      </CenteredText>
    </ContentContainer>
  </StartContainer>
);
export default ConnectScreen;
