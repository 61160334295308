import {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
  FC,
} from "react";
import { Client } from "@salus/sms-location-tracking-client";
import type { Position } from "../features/tracking";
import { useConfig } from "./Config";
type ErrorResponse = { error?: string };

export enum CallKinds {
  Active,
  Unknown,
  Closed,
}

interface ActiveCall {
  kind: CallKinds.Active;
  psapName: string;
}

interface UnknownCall {
  kind: CallKinds.Unknown;
}

interface ClosedCall {
  kind: CallKinds.Closed;
}

export type CallStatus = ActiveCall | UnknownCall | ClosedCall;

interface ClientInterface {
  loading: boolean;
  connected?: boolean;
  callStatus?: CallStatus;
  updateLocation: (location: Position) => void;
}

export const ClientContext = createContext<ClientInterface | null>(null);

let resolveLoading: (value?: unknown) => void;
const loading = new Promise((resolve) => (resolveLoading = resolve));

export const ClientProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [clientLoading, setClientLoading] = useState(true);
  const [connected, setConnected] = useState<boolean | undefined>(undefined);
  const [callStatus, setCallStatus] = useState<CallStatus | undefined>(
    undefined
  );
  const client = useRef<Client | null>(null);
  const { config } = useConfig();

  const getCallStatus = async (): Promise<void> => {
    await loading;
    try {
      // TODO: Make timeout configurable?
      const { psapExternalDisplayName, callStatus: status } =
        await client.current!.lookupCall({ timeout: 5000 });
      setConnected(true);
      setCallStatus(
        status === "closed"
          ? { kind: CallKinds.Closed }
          : {
              kind: CallKinds.Active,
              psapName: psapExternalDisplayName,
            }
      );
    } catch (e) {
      if (!(e instanceof Object)) {
        console.error("getCallStatus: unknown error", e);
        throw e;
      }
      const error = e as ErrorResponse;
      switch (error.error) {
        case "notFound":
          setConnected(true);
          setCallStatus({ kind: CallKinds.Unknown });
          return;
        case "timeout":
          setConnected(false);
          setTimeout(getCallStatus, 1000);
          return;
        default:
          //FIXME handle properly
          console.error(
            "getCallStatus: unspecifically handled error",
            error.error
          );
          throw e;
      }
    }
  };

  const init = async () => {
    const servers = config.servers;
    console.info("=== initializing client ===", { servers });
    client.current = new Client(
      {
        servers,
        connectionAvailabilityTimeoutMs: 10000,
        locationUpdateThrottleThresholdMs: 2000,
      },
      window.location.href,
      {
        general: {
          failure: console.error,
        },
        connection: {
          available: (_1) => setConnected(true),
          unavailable: (_1) => setConnected(false),
        },
        smsLocationTracking: {
          callClosed: (_1) =>
            setCallStatus({
              kind: CallKinds.Closed,
            }),
        },
      }
    );
    await client.current!.start();
    setClientLoading(false);
    getCallStatus();
    resolveLoading();
  };

  useEffect(() => {
    init();
  }, []);

  const updateLocation = async (location: Position): Promise<boolean> => {
    await loading;
    const { coords, timestamp } = location;
    const { longitude, latitude, accuracy } = coords;
    return client.current!.updateLocation({
      longitude,
      latitude,
      accuracy,
      acquiredAt: timestamp,
    });
  };

  const api: ClientInterface = {
    loading: clientLoading,
    connected,
    callStatus,
    updateLocation,
  };

  return (
    <ClientContext.Provider value={api}>{children}</ClientContext.Provider>
  );
};

export const useClient = () => {
  const client = useContext(ClientContext);
  if (client === null) {
    throw new Error(
      "useClient must be used within a ClientContext.Provider tag"
    );
  }

  return client;
};
