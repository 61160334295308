import { FC } from "react";
import "core-js/es/map";
import "core-js/es/set";
import "core-js/full/promise";
import "whatwg-fetch";
import ReactDOM from "react-dom/client";
import styled from "styled-components";
import { Provider } from "react-redux";
import { MainView } from "./views/main";
import LoadingApp from "./screens/LoadingApp";
import { store } from "./app/store";
import { ClientProvider } from "./context/Client";
import { useConfig, ConfigProvider } from "./context/Config";
import "./style.css";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ConfigWaiter: FC = () => {
  const { loading, errors } = useConfig();

  if (errors.length) {
    return (
      <span>An unexpected error occurred when fetching the configuration</span>
    );
  }

  if (loading) {
    return <LoadingApp />;
  }

  return (
    <ClientProvider>
      <Provider store={store}>
        <MainView />
      </Provider>
    </ClientProvider>
  );
};
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <ConfigProvider>
    <Main>
      <ConfigWaiter />
    </Main>
  </ConfigProvider>
);
