import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

interface LocationTransmitter {
  txInterval: number;
}

interface OSM {
  tileUrl: string;
  fqdns: string[];
}

interface Config {
  locationTransmitter: LocationTransmitter;
  osm: OSM;
  servers: { hostname: string }[];
}

interface Context {
  config: Config;
  errors: string[];
  loading: boolean;
}

export const ConfigContext = createContext<Context>({
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: null as any as Config, // assert that we have a config, because the loading state will catch that
  errors: [] as string[],
  loading: true,
});

export const ConfigProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [config, setConfig] = useState(null as any as Config); //eslint-disable-line @typescript-eslint/no-explicit-any
  const [errors, setErrors] = useState([] as Array<string>);

  useEffect(() => {
    const fetchConfig = async (): Promise<void> => {
      try {
        const data = await fetch("/config.json");
        setConfig((await data.json()) as Config);
      } catch (error) {
        setErrors((e) => [...e, (error as Error).message]);
        // TODO: handle errors
        console.error("Error while getting /config.json", { error });
      }
    };
    fetchConfig();
  }, []);

  const loading = !config && !errors.length;
  return (
    <ConfigContext.Provider value={{ config, errors, loading }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): Context => useContext(ConfigContext);
