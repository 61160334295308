import { KeyboardEventHandler, MouseEventHandler, ReactNode, FC } from "react";
import styled, { css } from "styled-components";
import {
  Primary,
  Secondary,
  Option,
  Important,
  Inactive,
  Background,
  Emphasized,
  Active,
} from "../utils/AppColor";

export enum types {
  primary,
  secondary,
  option,
  important,
  active,
  blank,
}

interface ButtonStyle {
  large?: boolean;
  margin?: string | number;
  noexpand?: boolean;
  disabled?: boolean;
  inverted?: boolean;
}

export const colorsForType = ({
  btype,
  disabled,
  inverted,
}: ButtonStyle & { btype?: types }): {
  background: string;
  color: string;
  "border-color": string;
} => {
  if (disabled) {
    return inverted
      ? {
          background: Background,
          color: Inactive,
          "border-color": Inactive,
        }
      : {
          background: Inactive,
          color: Background,
          "border-color": Inactive,
        };
  }

  switch (btype) {
    case types.blank:
      return {
        background: Background,
        color: Primary,
        "border-color": Background,
      };
    case types.important:
      return inverted
        ? {
            background: Background,
            color: Important,
            "border-color": Important,
          }
        : {
            background: Important,
            color: Background,
            "border-color": Important,
          };
    case types.option:
      return inverted
        ? {
            background: Background,
            color: Option,
            "border-color": Option,
          }
        : {
            background: Option,
            color: Background,
            "border-color": Option,
          };
    case types.active:
      return inverted
        ? {
            background: Background,
            color: Active,
            "border-color": Active,
          }
        : {
            background: Active,
            color: Background,
            "border-color": Active,
          };
    case types.secondary:
      return inverted
        ? {
            background: Background,
            color: Secondary,
            "border-color": Secondary,
          }
        : {
            background: Secondary,
            color: Background,
            "border-color": Secondary,
          };
    case types.primary:
    default:
      return inverted
        ? {
            background: Background,
            color: Primary,
            "border-color": Primary,
          }
        : {
            background: Primary,
            color: Background,
            "border-color": Primary,
          };
  }
};

const StyledButton = styled.button<ButtonStyle & { btype?: types }>`
  ${({ margin }) =>
    css`
      margin: ${margin};
    `}
  width: ${({ noexpand }) => (noexpand ? "fit-content" : "100%")};
  max-width: 280px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background: ${Background};
  border-radius: 8px;
  border: 3px solid;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 110%;
  outline: none;

  ${({ btype, disabled, inverted }) =>
    colorsForType({ btype, disabled, inverted })}

  ${({ large }) =>
    large &&
    css`
      min-height: 60px;
    `}

    &:hover, :focus {
    ${({ btype, inverted, disabled }) => {
      if (disabled) {
        return null;
      }
      if (inverted || btype === types.blank) {
        return css`
          outline: ${Emphasized} solid 1px;
          outline-offset: -8px;
        `;
      }
      return css`
        outline: ${Background} solid 1px;
        outline-offset: -6px;
      `;
    }}
  }
`;

const Prefix = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex: 0 0 10px;
`;

const Suffix = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  flex: 0 0 10px;
`;

const Main = styled.span`
  text-align: center;
  flex: 1 1 auto;
`;

interface ButtonProps extends ButtonStyle {
  children: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  onClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  tabIndex?: number;
  type?: types;
  submit?: boolean;
}

/**
 * The overarching Button is a StyledButton with optional prefix and suffix.
 *
 * NOTE: We cannot use `type` on `button`, so we call it `btype` instead.
 */
const Button: FC<ButtonProps> = ({
  children,
  prefix,
  suffix,
  type,
  submit,
  ...bprops
}) => (
  <StyledButton
    btype={type}
    {...bprops}
    {...(submit ? { type: "submit" } : null)}
  >
    {prefix && <Prefix>{prefix}</Prefix>}
    <Main>{children}</Main>
    {suffix && <Suffix>{suffix}</Suffix>}
  </StyledButton>
);

export default Button;
