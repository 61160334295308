enum Color {
  Background = "#ffffff",
  SemiTransparentBackground = "#ffffffB0",
  Primary = "#006689",
  Secondary = "#007FAB",
  Active = "#004C67",
  Inactive = "#CCE5EE",
  Option = "#FF9900",
  Alert = "#219B52",
  Important = "#E80B1C",
  PressedPrimary = "#3399BC",
  PressedSecondary = "#66B2CD",
  PressedOption = "#FFC266",
  PressedImportant = "#F69DA4",
  Emphasized = "#001922",
  Hint = "#FFAD33",
  Warn = "#E36B27",
  Urgent = "#C82A1C",
  Shadow = "#000000",
  TransparentBackground = "rgba(0,0,0,0.5)",
  MenuSeparator = "#c4c4c4", // FIXME: find a better name :p
  Placeholder = "#66B2CD",
  ButtonText = "#ffffff",
}

export default Color;

//TODO: This could look nicer...
export const Active = Color.Active;
export const Alert = Color.Alert;
export const Background = Color.Background;
export const ButtonText = Color.ButtonText;
export const Emphasized = Color.Emphasized;
export const Hint = Color.Hint;
export const Important = Color.Important;
export const Inactive = Color.Inactive;
export const MenuSeparator = Color.MenuSeparator;
export const Option = Color.Option;
export const Placeholder = Color.Placeholder;
export const PressedImportant = Color.PressedImportant;
export const PressedOption = Color.PressedOption;
export const PressedPrimary = Color.PressedPrimary;
export const PressedSecondary = Color.PressedSecondary;
export const Primary = Color.Primary;
export const Secondary = Color.Secondary;
export const SemiTransparentBackground = Color.SemiTransparentBackground;
export const Shadow = Color.Shadow;
export const TransparentBackground = Color.TransparentBackground;
export const Urgent = Color.Urgent;
export const Warn = Color.Warn;
